export const REQUEST_TABLE_HEADER = [
  {
    label: " ",
    field: "imageLink",
  },
  {
    label: "Date",
    field: "created_date",
    type: "date",
    dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
    dateOutputFormat: "dd-MM-yyyy",
    tdClass: "text-center",
  },
  {
    label: "Code",
    field: "code",
  },
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Description",
    field: "description",
  },
  {
    label: "Remark",
    field: "remark",
  },
  {
    label: "Requested By",
    field: "user.name",
  },
  {
    label: "Branch",
    field: "branch.code",
    tdClass: "text-center",
  },
  {
    label: "Actions",
    field: "actions",
  },
];
