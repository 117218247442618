<template>
  <div>
    <plain-card>
      <v-col cols="12" sm="6" md="4" class="mb-n12 mt-n5">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="filters.dates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Select Date Range"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              solo
              clearable
              @click:clear="clearDatesAndRefresh()"
              small
            ></v-text-field>
          </template>
          <v-date-picker v-model="filters.dates" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(filters.dates), onInit()"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </plain-card>
    <vue-good-table
      :columns="columns"
      :rows="requests"
      @on-selected-rows-change="selectionChanged"
      :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
      :search-options="{ enabled: true }"
      class="mb-10"
      styleClass="vgt-table condensed bordered"
    >
      <template slot="table-column" slot-scope="props">
        <span style="font-weight: bold; font-size: 12px; color: black"> {{ props.column.label }} </span>
      </template>
      <template slot="table-row" slot-scope="props">
        <!-- #region Image Column -->
        <span v-if="props.column.field == 'imageLink'">
          <el-image
            :src="getImage(props.row.imageLink)"
            style="width: 80px; height: 80px"
            class="my-2 rounded-lg"
            alt="Loading"
            lazy
            fit="fit"
            @click="previewURL(scope.row.imageLink)"
          />
        </span>
        <!-- #endregion -->

        <!-- #region Branch Column -->
        <!-- <span v-else-if="props.column.field == 'user.branch'" style="font-size: 13px">
          <p v-for="item in props.row.user.branch" :key="item">
            {{ item.code }}
          </p>
        </span> -->
        <!-- #endregion -->

        <!-- #region Actions Column -->
        <span v-else-if="props.column.field == 'actions'" style="font-size: 13px">
          <div class="d-flex flex-column">
            <v-btn elevation="2" x-small color="success" class="ma-2" @click="(selected.request = props.row), (dialogs.newProduct = true)">
              Accept
            </v-btn>
            <v-btn elevation="2" x-small color="error" class="ma-2" @click="(selected.request = props.row), (dialogs.rejection = true)">
              Reject
            </v-btn>
          </div>
        </span>
        <!-- #endregion -->

        <!-- #region Other Rows -->
        <span v-else style="font-size: 13px">
          {{ props.formattedRow[props.column.field] }}
        </span>
        <!-- #endregion -->
      </template>
    </vue-good-table>
    <ProductForm :dialog.sync="dialogs.newProduct" :_editItems.sync="selected.request" :editIndex="0" @onSave="addProductToInventory" />
    <RejectReasonModal :visible.sync="dialogs.rejection" :rejectReason.sync="selected.rejectReason" @save="rejectRequest" />
  </div>
</template>

<script>
import PlainCard from "../../components/PlainCard.vue";
import RejectReasonModal from "../../components/RejectReasonModal.vue";
import { getImage } from "../../common/utils";
import { imagePreviewHelper } from "../../config/constants";
import { getAllRequests, moveFile, updateRequest } from "./lib";
import { REQUEST_TABLE_HEADER } from "./constants/Tableheaders";
import ProductForm from "../../components/ProductForm.vue";

export default {
  name: "Requests",
  components: {
    PlainCard,
    RejectReasonModal,
    ProductForm,
  },
  computed: {
    dateRangeText() {
      return this.filters.dates.join(" ~ ");
    },
  },
  watch: {
    "dialogs.rejection"(val) {
      if (!val) {
        this.selected.requestId = null;
      }
    },
    "dialogs.newProduct"(val) {
      if (val) {
        this.selected.request.branch = [this.selected.request.branch];
      }
    },
  },
  data() {
    return {
      requests: [],
      dialogs: {
        newProduct: false,
        rejection: false,
      },
      columns: REQUEST_TABLE_HEADER,
      selected: {
        request: null,
        rejectReason: "",
      },
      filters: {
        dates: [],
      },
    };
  },
  methods: {
    getImage: getImage,
    previewURL(imageLink) {
      this.$viewerApi(imagePreviewHelper(imageLink));
    },
    setFiltersDropdown() {
      // ItemCode Filter Array
      this.$set(this.columns[2], "filterOptions", {
        enabled: true,
        placeholder: "ALL",
        filterDropdownItems: [...new Set(this.requests.map((item) => item.code.toUpperCase()))].sort((a, b) => a.localeCompare(b)),
      });
      // ItemName Filter Array
      this.$set(this.columns[3], "filterOptions", {
        enabled: true,
        placeholder: "ALL",
        filterDropdownItems: [...new Set(this.requests.map((item) => item.name))].sort((a, b) => a.localeCompare(b)),
      });
      // ItemName Filter Array
      this.$set(this.columns[6], "filterOptions", {
        enabled: true,
        placeholder: "ALL",
        filterDropdownItems: [...new Set(this.requests.map((item) => item.user.name))].sort((a, b) => a.localeCompare(b)),
      });
      // ItemName Filter Array
      this.$set(this.columns[7], "filterOptions", {
        enabled: true,
        placeholder: "ALL",
        filterDropdownItems: [...new Set(this.requests.map((item) => item.branch.code))].sort((a, b) => a.localeCompare(b)),
      });
    },
    async clearDatesAndRefresh() {
      this.filters.dates = [];
      await this.onInit();
    },
    async onInit() {
      let _dateQueryParam = {};
      if (this.filters.dates.length !== 0) {
        _dateQueryParam = {
          startDate: this.filters.dates[0],
          endDate: this.filters.dates[1],
        };
      }
      this.requests = await getAllRequests(_dateQueryParam);
      this.setFiltersDropdown();
    },
    async addProductToInventory() {
      this.dialogs.newProduct = false;
      await this.$store.dispatch("setLoading", true);
      let moveFileBody = {
        key: this.selected.request.imageLink,
        targetPath: "product_images",
      };

      let newKey = await moveFile(moveFileBody);
      this.selected.request.imageLink = newKey;

      const res = await updateRequest(this.selected.request.id, "", "COMPLETED", this.selected.request);

      if (res.status === 200) {
        // TODO Remove data from local instead of calling the API again
        this.selected.request = null;
        await this.$store.dispatch("setLoading", false);
        await this.onInit();
        this.$notify({
          title: "Success",
          text: "Product added successfully",
          type: "success",
        });
      } else {
        await this.$store.dispatch("setLoading", false);
        this.$notify({
          title: `Product code already exists`,
          text: "Error",
          type: "error",
        });
      }
    },
    async rejectRequest() {
      await updateRequest(this.selected.request.id, this.selected.rejectReason, "REJECTED", null);

      this.onInit();
      this.$notify({
        title: "Success",
        text: "Request Rejected Successfully",
        type: "success",
      });
      this.dialogs.rejection = false;
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.onInit();
    await this.$store.dispatch("setLoading", false);
  },
};
</script>

<style></style>
