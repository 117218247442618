import axios from "axios";

async function getAllRequests({ startDate, endDate }) {
  let queryStr = "";
  if (startDate) {
    queryStr = `?startDate=${startDate}&endDate=${endDate}`;
  }
  try {
    const response = await axios.get(`request/all${queryStr}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function moveFile(data) {
  try {
    const response = await axios.post(`s3/moveFile`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function moveToProduct(request_id, data) {
  try {
    const res = await axios.post(`request-product/moveProductImage/${request_id}`, data);
    return res.data;
  } catch (error) {
    return error;
  }
}

async function updateRequest(id, remark, status, productDetails) {
  try {
    let body = {
      status,
      remark,
      productDetails: createProductObjectGeneration(productDetails),
    };

    return await axios.patch(`request/update/${id}`, body);
  } catch (error) {
    return error;
  }
}

async function removeRequest(id) {
  try {
    const res = await axios.delete(`request/remove/${id}`);
    return res.data;
  } catch (error) {
    return error;
  }
}

function createProductObjectGeneration(data) {
  if (!data) return null;
  let _valid_date = new Date(data.validTill);
  if (data.validTill) {
    _valid_date = _valid_date.setDate(_valid_date.getDate() + 1);
    _valid_date = new Date(_valid_date).toISOString().substr(0, 10);
  } else {
    _valid_date = data.validTill;
  }

  let _data = {
    code: data.code,
    name: data.name,
    description: data.description,
    price: Number(data.price),
    minimumOrderQuantity: data.minimumOrderQuantity,
    category: data.category,
    supplierCode: data.supplierCode,
    validTill: _valid_date,
    transportType: data.transportType,
    imageLink: data.imageLink,
    branch: data.branch,
    color: data.color,
  };

  return _data;
}

export { getAllRequests, moveToProduct, updateRequest, moveFile, removeRequest };
